<template>
  <div class="input-item">
    <el-input
      :placeholder="inputPlaceholder"
      :show-password="inputShowPassword"
      :autocomplete='autoComplete'
      v-model="value"
    >
      <img class="input-prepend-icon" :src="inputPrependIcon" alt="prepend" slot="prepend">
      <template v-if="inputShowAppend">
        <img v-if="inputAppendType === 'img'" src="" alt="append" slot="append">
        <span v-else
          class="input-append-operation"
          slot="append"
          type="primary"
          @click="operationFun"
        >{{ inputAppendOperation }}</span>
      </template>
    </el-input>
  </div>
</template>

<script>
const defaultIcon = require('@/assets/images/icon_phone@3x.png');

export default {
  name: 'InputItem',
  props: {
    inputPlaceholder: {
      type: String,
      default: '请输入内容',
    },
    autoComplete: {
      type: String,
      default: 'new-password',
    },
    inputShowPassword: {
      type: Boolean,
      default: false,
    },
    inputPrependIcon: {
      type: String,
      default: defaultIcon,
    },
    inputShowAppend: {
      type: Boolean,
      default: true,
    },
    inputAppendType: {
      type: String,
      default: 'operation',
    },
    inputAppendOperation: {
      type: String,
      default: '操作',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    operationFun() {
      this.$emit('operationHandler');
    },
    getInputValue() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input-group__append {
  cursor: pointer;
  padding: 0 12px;
  font-size: 12px;
  background-color: #409eff;
  color: #ffffff;
}
</style>
