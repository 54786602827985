<template>
  <div class="input-form">
    <p class="main-title">{{ mainTitle }}</p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Input-Form',
  props: {
    mainTitle: {
      type: String,
      default: '表单主标题',
    },
  },
};
</script>

<style lang="scss" scoped>
.input-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 23%;
  right: 15.6%;
  width: 23.1%;
  min-width: 315px;
  height: 54.1%;
  min-height: 355px;
  background-color: #FFFFFF;
  box-shadow: 0 6px 10px 0 #DDDDDD;
  border-radius: 10px;

  .main-title {
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (min-width: 1920px) {
    .main-title {
      font-size: 24px;
    }
  }
}
</style>
