<template>
  <div class="login-layout">
    <div class="top-bar">
      <img src="../../../assets/images/assistLogo.png"
           style="width: 24px;vertical-align: middle;border-radius: 2px;margin-right: 2px">
      山东省高考志愿一点通
    </div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'LoginLayout',
};
</script>

<style lang="scss" scoped>
  .login-layout {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/background@3x.png");
    background-size: 100% 100%;
  }

  .top-bar {
    width: 100%;
    height: 48px;
    padding-left: 13.5%;
    line-height: 48px;
    text-align: left;
    font-size: 20px;
    color: #FFFFFF;
    background-image: linear-gradient(135deg, #409EFF 0%, #1A6DFF 100%);
    box-shadow: 0 1px 4px 0 rgba(1, 31, 74, 0.20);
  }
</style>
