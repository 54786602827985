<template>
  <div>
    <Vcode
      :imgs="imageList"
      :show="capShow"
      @success="onSuccess"
      @close="onClose"
    />
  </div>
</template>

<script type="text/javascript">
import Vcode from 'vue-puzzle-vcode';
import img1 from '@/assets/images/1-300x160.jpg';
import img2 from '@/assets/images/2-300x160.jpg';

export default {
  name: 'Cap',
  components: {
    Vcode,
  },
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      capShow: false,
      imageList: [
        'https://picsum.photos/300/160',
        img1,
        img2,
      ],
    };
  },
  watch: {
    isShow(val) {
      this.capShow = val;
    },
  },
  methods: {
    // 用户通过了验证
    onSuccess() {
      this.$emit('showReceipt', true);
    },
    // 用户点击遮罩层，应该关闭模态框
    onClose() {
      this.$emit('showReceipt', false);
    },
  },
};
</script>

<style scoped>
  .container {
    width: 310px;
    margin: 100px auto;
  }

  #msg {
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    margin-left: 100px;
    color: #0366D6;
  }

  .block {
    position: absolute;
    left: 0;
    top: 0;
  }

  .sliderContainer {
    position: relative;
    text-align: center;
    width: 310px;
    height: 40px;
    line-height: 40px;
    margin-top: 15px;
    background: #f7f9fa;
    color: #45494c;
    border: 1px solid #e4e7eb;
  }

  .sliderContainer_active .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #1991FA;
  }

  .sliderContainer_active .sliderMask {
    height: 38px;
    border-width: 1px;
  }

  .sliderContainer_success .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #52CCBA;
    background-color: #52CCBA !important;
  }

  .sliderContainer_success .sliderMask {
    height: 38px;
    border: 1px solid #52CCBA;
    background-color: #D2F4EF;
  }

  .sliderContainer_success .sliderIcon {
    background-position: 0 0 !important;
  }

  .sliderContainer_fail .slider {
    height: 38px;
    top: -1px;
    border: 1px solid #f57a7a;
    background-color: #f57a7a !important;
  }

  .sliderContainer_fail .sliderMask {
    height: 38px;
    border: 1px solid #f57a7a;
    background-color: #fce1e1;
  }

  .sliderContainer_fail .sliderIcon {
    background-position: 0 -83px !important;
  }

  .sliderContainer_active .sliderText,
  .sliderContainer_success .sliderText,
  .sliderContainer_fail .sliderText {
    display: none;
  }

  .sliderMask {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    border: 0 solid #1991FA;
    background: #D1E9FE;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: background .2s linear;
  }

  .slider:hover {
    background: #1991FA;
  }

  .slider:hover .sliderIcon {
    background-position: 0 -13px;
  }

  .sliderIcon {
    position: absolute;
    top: 15px;
    left: 13px;
    width: 14px;
    height: 10px;
  }

  .refreshIcon {
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;
    height: 34px;
    cursor: pointer;
  }
</style>
