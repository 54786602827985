// 手机号
const mobile = /^0?(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])[0-9]{8}$/;
const number = /^\d+(\.\d+)?$/;
const number1 = /^(\d+)(.\d{1,1})?$/;
const number2 = /^(\d+)(.\d{1,2})?$/;

const regExp = {
  phone: mobile,
  number,
  number1,
  number2,
};

export default regExp;
